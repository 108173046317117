<script setup>
import { ref } from 'vue';

const props = defineProps({
    label: String,
    value: Object,
    errors: Array,
});
const emits = defineEmits(['update:value']);

const nextValue = ref('');
const remove = (i) => {
    emits('update:value', props.value.splice(i, 1));
};
const add = (i) => {
    if (nextValue.value == '') {
        return;
    }
    emits('update:value', props.value.push(nextValue.value));
    nextValue.value = '';
};
</script>

<template>
    <div class="w-full formkit-outer">
        <label for="" class="block mb-2 font-medium leading-tight formkit-label">
            {{ label }}
        </label>
        <div class="relative">
            <input
                v-model="nextValue"
                type="text"
                class="
                    block
                    w-full
                    px-4
                    py-2
                    placeholder-black
                    bg-white
                    border border-transparent
                    rounded-md
                    formkit-input
                    focus:outline-none
                    focus:text-gray-800
                    focus:placeholder-gray-500-400
                    focus:ring-1
                    focus:ring-green-500
                    focus:border-green-500
                    sm:text-sm
                    disabled:bg-gray-500-100
                    min-h-10
                "
            />

            <div
                @click="add"
                class="
                    absolute
                    flex
                    items-center
                    justify-center
                    w-6
                    h-6
                    -translate-y-1/2
                    rounded-full
                    bg-green-500
                    right-2
                    top-1/2
                "
            >
                <img class="m-0" src="/icon/add_48-white.svg" alt="" />
            </div>
        </div>

        <div class="flex flex-wrap gap-2 mt-2">
            <div v-for="(v, i) in value" :key="i" class="flex gap-2 px-4 py-2 text-white rounded-full bg-green-500">
                {{ $t(v) }}

                <img @click="remove(i)" class="m-0 cursor-pointer" src="/icon/remove.svg" alt="" />
            </div>
        </div>
    </div>
</template>