<script setup>
import Button from '@/Components/Button/Button.vue';
import Array from '@/Components/Input/Array.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    flexer: Object,
});

const form = useForm({
    ...props.flexer,
});

const sendForm = () => {
    form.put(route('staff.flexers.update', { flexer: props.flexer.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>
<template>
    <FormKit type="form" id="FlexerUpdateForm" @submit="sendForm" :actions="false">
        <div class="grid gap-5">
            <FormKit
                type="text"
                name="first_name"
                :label="$t('First name')"
                v-model="form.first_name"
                :errors="form.errors['first_name']"
                :value="form.first_name"
                validate="required"
            />

            <FormKit
                type="text"
                name="last_name"
                :label="$t('Last name')"
                v-model="form.last_name"
                :errors="form.errors['last_name']"
                :value="form.last_name"
                validate="required"
            />
            <FormKit
                type="text"
                name="nickname"
                :label="$t('Calling name')"
                v-model="form.nickname"
                :errors="form.errors['nickname']"
                :value="form.nickname"
                validate="required"
            />

            <FormKit
                type="date"
                name="date_of_birth"
                :label="$t('Date of birth')"
                v-model="form.date_of_birth"
                :errors="form.errors['date_of_birth']"
                :value="form.date_of_birth"
                validate="required"
            />

            <FormKit
                type="text"
                name="phone_number"
                :label="$t('Phone number')"
                v-model="form.phone_number"
                :errors="form.errors['phone_number']"
                :value="form.phone_number"
                validate="required"
            />

            <FormKit
                type="text"
                name="email"
                :label="$t('Email address')"
                v-model="form.email"
                :errors="form.errors['email']"
                :value="form.email"
                validate="required"
            />

            <Array :label="$t('Properties')" v-model:value="form.properties" :errors="form.errors['properties']" />
            <Button
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                icon="/icon/done-white.svg"
                :text="$t('Save')"
            />
        </div>
    </FormKit>
</template>
